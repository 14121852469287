import React from 'react'
import { Link, graphql } from 'gatsby'

import Img from 'gatsby-image/withIEPolyfill'
import BackgroundImage from 'gatsby-background-image'

import Layout from '../components/layout'
import SEO from '../components/seo'

import styled from 'styled-components'
import { Container, PageWrapper } from '../components/common/container'
import { Title } from '../components/common/title'
import { HeroSection } from '../components/common/hero-section'
import { CustomCard } from '../components/common/card'

import colors from '../data/color-pallette'
// import {
//   Grid,
//   Cell,
//   Button,
//   SVGIcon,
//   CardTitle,
//   CardText,
//   GridList,
// } from 'react-md'

import Cell from 'react-md/lib/Grids/Cell'
import GridList from 'react-md/lib/Grids/GridList'
import breakpoints from '../data/breakpoints'
import colorPallette from '../data/color-pallette'

const CustomHeroSection = styled(HeroSection)`
  height: 300px;
`

const HeroTitle = styled(Title)`
  color: ${colors.white};
  margin-bottom: 12px;
`

const InfoBox = styled.div`
  background-color: ${colorPallette.secondary};
  text-align: center;
  padding: 42px 10px;
  color: white;

  h3,
  p {
    color: white;
    white-space: normal;
  }
`

const NewsLink = styled(Link)`
  text-decoration: none;
`

const NewsCard = styled(CustomCard)`
  padding: 16px;
  height: 100%;

  h3 {
    font-weight: 600;
    margin: 0;
    color: ${colors.secondary};
  }

  .post-date {
    color: #333;
    font-size: 12px;
    margin-bottom: 14px;
  }

  p {
    height: 60px;
    overflow: hidden;
    text-indent: 20px;
    padding: 0 20px;

    a {
      margin-left: 8px;
    }
  }
`

const NewsImage = styled(Img)`
  min-height: 100px;
`

const BetaWarning = styled.div`
  text-align: center;
  padding: 24px;
  background: ${colors.error};

  h3,
  p {
    color: white;
  }

  h3 {
    font-weight: 500;
    font-size: 22px;
  }
`

const IndexPage = props => {
  const {
    data: {
      heroImage,
      news,
      site: {
        siteMetadata: { dashboardUrl },
      },
    },
  } = props

  return (
    <Layout>
      <SEO title="News | StreamParrot - The best branding available for your chat overlay" />
      <PageWrapper>
        <div>
          <BackgroundImage
            fluid={{
              base64: heroImage.childImageSharp.lowRes.dataURI,
              src: heroImage.childImageSharp.hiRes.dataURI,
            }}
          >
            <CustomHeroSection>
              <HeroTitle>News</HeroTitle>
            </CustomHeroSection>
          </BackgroundImage>
          <InfoBox>
            <Container>
              <h3>Check back here to get the latest in StreamParrot news!</h3>
            </Container>
          </InfoBox>

          <BetaWarning>
            <h3>Upcoming Beta</h3>
            <p>
              Join the beta to get early access and help shape the direction of
              the service.
            </p>
            <a
              href={`${dashboardUrl}/login`}
              className="md-btn md-btn--flat md-btn--raised md-btn--text md-btn--raised-pressed md-pointer--hover md-background--secondary md-background--secondary-hover md-inline-block"
              secondary
              flat
              raised
            >
              Sign Up
            </a>
          </BetaWarning>
          <Container>
            <GridList>
              {news.nodes.map(newsPost => (
                <Cell>
                  <NewsLink to={newsPost.frontmatter.path}>
                    <NewsCard>
                      <NewsImage
                        fluid={{
                          base64:
                            newsPost.frontmatter.featuredImage.childImageSharp
                              .sqip.dataURI,
                        }}
                      />
                      <h3>{newsPost.frontmatter.title}</h3>
                      <div class="post-date">{newsPost.frontmatter.date}</div>
                      <p>
                        {newsPost.frontmatter.summary}
                        <Link to={newsPost.frontmatter.path}>read more</Link>
                      </p>
                    </NewsCard>
                  </NewsLink>
                </Cell>
              ))}
            </GridList>
          </Container>
        </div>
      </PageWrapper>
    </Layout>
  )
}
export default IndexPage

export const pageQuery = graphql`
  query($SQIP_HERO_SHAPE_COUNT: Int, $SQIP_THUMB_SHAPE_COUNT: Int) {
    site {
      siteMetadata {
        dashboardUrl
      }
    }
    news: allMarkdownRemark(
      filter: { frontmatter: { postType: { eq: "news" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 1000
    ) {
      nodes {
        html
        frontmatter {
          postType
          path
          title
          date(formatString: "MMMM DD, YYYY")
          summary
          featuredImage {
            childImageSharp {
              sqip(
                numberOfPrimitives: $SQIP_THUMB_SHAPE_COUNT
                blur: 0
                width: 256
                mode: 1
              ) {
                dataURI
              }
            }
          }
        }
      }
    }
    heroImage: file(relativePath: { regex: "/holding-light-bulb.jpeg/" }) {
      ...HeroImage
    }
  }
`
